import React, { useMemo } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { AppRoutesEnum } from '~/enums/route.enum';
import { useGetEndpointSerivceUserStatusQuery } from '~/graphql/endpoint-service/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const EndpointServiceRegistration = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: userStatusData, loading: getUserStatusLoading } =
    useGetEndpointSerivceUserStatusQuery({
      fetchPolicy: 'cache-first',
    });
  const isRegister = userStatusData?.getEndpointSerivceUserStatus.isAcceptTermOfService;

  const renderStatus = useMemo(() => {
    if (getUserStatusLoading) {
      return (
        <Skeleton>
          <Chip label="xxxxxxxxxx" />
        </Skeleton>
      );
    }
    if (isRegister) {
      return <Chip className={classes.chipRegistered} label={t('registered')} />;
    }
    return <Chip label={t('unregistered')} />;
  }, [getUserStatusLoading, isRegister, t, classes.chipRegistered]);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>
            <Typography variant="h6">
              {t('endpoint_service_page.endpoint_service_title')}
            </Typography>
          </div>
          <div className={classes.label}>{renderStatus}</div>
        </div>
        <Typography variant="body1" color="textSecondary" className={classes.message}>
          {t('endpoint_service_page.register_endpoint_service_text')}
        </Typography>
      </div>
      <div className={classes.bottom}>
        {getUserStatusLoading ? (
          <Skeleton height={29} />
        ) : (
          <Link to={AppRoutesEnum.ENDPOINT_SERVICE}>
            <div className={classes.button}>
              <Typography variant="body1">{isRegister ? t('go') : t('register')}</Typography>
              <NavigateNextIcon className={classes.buttonIcon} />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default EndpointServiceRegistration;
