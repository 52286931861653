import React, { useMemo } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { AppRoutesEnum } from '~/enums/route.enum';
import { useGetDevProgramMemberStatusQuery } from '~/graphql/development-program/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const DevelopmentProgramRegistration = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: devProgramMemberStatusData, loading: getMemberStatusLoading } =
    useGetDevProgramMemberStatusQuery({
      fetchPolicy: 'cache-first',
    });
  const isRegister = devProgramMemberStatusData?.getDevProgramMemberStatus?.isAcceptTermOfService;
  const renderStatus = useMemo(() => {
    if (getMemberStatusLoading) {
      return (
        <Skeleton>
          <Chip label="xxxxxxxxxx" />
        </Skeleton>
      );
    }
    if (isRegister) {
      return <Chip className={classes.chipRegistered} label={t('registered')} />;
    }
    return <Chip label={t('unregistered')} />;
  }, [getMemberStatusLoading, isRegister, t, classes.chipRegistered]);
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>
            <Typography variant="h6">
              {t('development_program.development_program_title')}
            </Typography>
          </div>
          <div className={classes.label}>{renderStatus}</div>
        </div>
        <Typography variant="body1" color="textSecondary" className={classes.message}>
          {t('development_program.register_development_program_text')}
        </Typography>
      </div>
      <div className={classes.bottom}>
        {getMemberStatusLoading ? (
          <Skeleton height={29} />
        ) : (
          <Link to={AppRoutesEnum.DEVELOPMENT_PROGRAM}>
            <div className={classes.button}>
              <Typography variant="body1">{isRegister ? t('go') : t('register')}</Typography>
              <NavigateNextIcon className={classes.buttonIcon} />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default DevelopmentProgramRegistration;
